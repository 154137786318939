import React from 'react';
import logo from '../Components/img/logo white.png';

const Footer = () => {
    return (
        <footer className="bg-[#0E2334] text-white pb-10 lg:pt-1 pt-10 lg:mt-[120px] mt-[50px]">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 lg:w-[80%] lg:mt-[100px]">
                <div className="flex flex-wrap justify-around">
                    <div className="w-full md:w-1/4 lg:w-[230px] mb-8 md:mb-0 flex flex-col justify-center lg:items-start lg:me-[50px] ">
                        <a href="/" className=''>
                            <img className="w-[100px]" src={logo} alt="Sure Shift Logo" />
                        </a>
                        <p className="mb-4 lg:w-[250px] lg:mt-[10px] lg:mb-[30px] mt-5">
                            Sure Shift Relocation Service provides reliable and efficient relocation services, ensuring your move is smooth and hassle-free. Trust us to handle your belongings with care and professionalism.
                        </p>
                        <div className="flex space-x-5">
                            <a href="/" className="text-red-500 bg-[#D81F47] flex justify-center items-center border border-[#D81F47] rounded-full h-[35px] w-[35px] lg:h-[40px] lg:w-[40px]">
                                <i className="fab fa-facebook-f text-white"></i>
                            </a>
                            <a href="/" className="text-red-500 bg-[#D81F47] flex justify-center items-center border border-[#D81F47] rounded-full h-[35px] w-[35px] lg:h-[40px] lg:w-[40px]">
                                <i className="fab fa-instagram text-white"></i>
                            </a>
                            <a href="/" className="text-red-500 bg-[#D81F47] flex justify-center items-center border border-[#D81F47] rounded-full h-[35px] w-[35px] lg:h-[40px] lg:w-[40px]">
                                <i className="fab fa-twitter text-white"></i>
                            </a>
                            <a href="/" className="text-red-500 bg-[#D81F47] flex justify-center items-center border border-[#D81F47] rounded-full h-[35px] w-[35px] lg:h-[40px] lg:w-[40px]">
                                <i className="fab fa-linkedin text-white"></i>
                            </a>
                        </div>
                    </div>

                    {/* Mobile View  */}
                    <div className='w-full flex justify-between items-top md:hidden'>
                        <div className="w-full md:w-1/4 mb-8 md:mb-0 ">
                            <h3 className="font-bold mb-4 text-[#D81F47] ">USEFUL LINK</h3>
                            <ul className='space-y-3'>
                                <li><a href="/about_us" className="hover:text-red-500">About</a></li>
                                <li><a href="/#contact" className="hover:text-red-500">Services</a></li>
                                <li><a href="/#tracking" className="hover:text-red-500">Tracking</a></li>
                                <li><a href="/login" className="hover:text-red-500">Admin Login</a></li>
                            </ul>
                        </div>
                        <div className="w-[50%] md:w-1/4 mb-8 md:mb-0">
                            <h3 className="font-bold mb-4 text-[#D81F47]">COMPANY</h3>
                            <ul className='space-y-3'>
                                <li><a href="/" className="hover:text-red-500">Team</a></li>
                                <li><a href="/" className="hover:text-red-500">Career</a></li>
                                <li><a href="/" className="hover:text-red-500">Blog</a></li>
                                <li><a href="/" className="hover:text-red-500">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                    {/* mobile View End */}

                    <div className="w-full md:w-1/4 mb-8 md:mb-0 md:block hidden">
                        <h3 className="font-bold mb-4 text-[#D81F47] ">USEFUL LINK</h3>
                        <ul className='space-y-3'>
                            <li><a href="/about_us" className="hover:text-red-500">About</a></li>
                            <li><a href="/#contact" className="hover:text-red-500">Services</a></li>
                            <li><a href="/#tracking" className="hover:text-red-500">Tracking</a></li>
                            <li><a href="/login" className="hover:text-red-500">Admin Login</a></li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/4 mb-8 md:mb-0 md:block hidden">
                        <h3 className="font-bold mb-4 text-[#D81F47]">COMPANY</h3>
                        <ul className='space-y-3'>
                            <li><a href="/" className="hover:text-red-500">Team</a></li>
                            <li><a href="/" className="hover:text-red-500">Career</a></li>
                            <li><a href="/" className="hover:text-red-500">Blog</a></li>
                            <li><a href="/#contact" className="hover:text-red-500">Contact Us</a></li>
                        </ul>
                    </div>

                    <div className="w-full md:w-1/4 mb-8 md:mb-0 space-y-3">
                        <h3 className="font-bold mb-4 text-[#D81F47]">CONTACT</h3>
                        <p className="mb-4"><i className="fas fa-map-marker-alt text-[#D81F47] me-3"></i>Gopal Nagar Extn, New Delhi, 110043</p>
                        <p className="mb-4"><i className="fas fa-phone text-[#D81F47] me-3"></i><a href="tel:+919073291732" className="hover:text-red-500">90 732 91 732</a></p>
                        <p className="mb-4"><i className="far fa-envelope text-[#D81F47] me-3"></i> <a href="mailto:info@sureshift.in" className="hover:text-red-500">info@sureshift.in</a></p>
                        <p className="mb-4"><i className="far fa-clock text-[#D81F47] me-3"></i>27 X 7</p>
                    </div>
                </div>
                <div className="border-t border-gray-700 mt-8 pt-6 text-center flex lg:flex-row flex-col justify-between items-center">
                    {/* <p className='lg:text-base text-sm'>© 2024 Sure Shift Relocation Service. Made with<i className="fas fa-heart text-[#D81F47] ml-1"></i> in India. Developed by <a href="https://creatorsadda.com/" className='cursor-pointer hover:text-red-500'>Creators Adda.</a></p> */}
                    <p className='lg:text-base text-sm hidden sm:block'>© 2024 Sure Shift Relocation Service.Made with<i className="fas fa-heart text-[#D81F47] ml-1"></i> in India.Developed by <a href="https://creatorsadda.com/" className='cursor-pointer hover:text-red-500'>Creators Adda.</a></p>
                    {/* mobile view */}
                    <p className='lg:hidden block text-sm'>© 2024 Sure Shift Relocation Service. <br/>Made with<i className="fas fa-heart text-[#D81F47] ml-1"></i> in India. <br/>Developed by <a href="https://creatorsadda.com/" className='cursor-pointer hover:text-red-500'>Creators Adda.</a></p>
                    <a href="/" className="hover:text-red-500 lg:-mt-0 mt-3"><i className="fas fa-arrow-circle-up text-[#D81F47] me-1"></i>Back To Top</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
